import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MobileNav } from '../mobile-nav';
import { SideNav } from './side-nav';
import { TopNav } from './top-nav';
import { useMobileNav } from './use-mobile-nav';
import {useSideNav} from "./use-side-nav";

const SIDE_NAV_WIDTH = 280;

const VerticalLayoutRoot = styled('div',
    { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  marginLeft:'5%',
      ...(open && {
        // [theme.breakpoints.up('lg')]: {
          marginLeft: SIDE_NAV_WIDTH,
        // },
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        })
      })
}));

const VerticalLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
});

export const VerticalLayout = (props) => {
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const mobileNav = useMobileNav();
  const sideNav = useSideNav();

  return (
    <>
      <TopNav onOpen={sideNav.handleOpen}  onClose={sideNav.handleClose} open={sideNav.open} />
        <SideNav
          onClose={sideNav.handleClose}
          onOpen={sideNav.handleOpen}
          open={sideNav.open}
          color={navColor}
          sections={sections}
          sideNavAction={sideNav}
        />
      <VerticalLayoutRoot  open={sideNav.open}>
        <VerticalLayoutContainer>
          {children}
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
  sections: PropTypes.array
};
