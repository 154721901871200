import { toast } from 'react-hot-toast';
import {Avatar, Box, Button, Typography} from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';

const duration = 3000;
export default async function BaseAlert(type, title, message, path) {
    const toastType = getTypeForToast(type);
    if (toastType === 'info') {
        await new Promise((resolve) => {
            return  toast((t)=>
                    <Box
                        sx={{
                            color: "#000000",
                            textAlign: 'start',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}

                    >
                        <Box >
                            <Avatar sx={{ backgroundColor: 'transparent' }}>
                                <Box
                                    sx={{
                                        animation: 'pulse ease 750ms infinite',
                                        borderRadius: '50%',
                                        '@keyframes pulse': {
                                            '0%': {
                                                boxShadow: 'none'
                                            },
                                            '100%': {
                                                boxShadow: `0px 0px 0px 6px #A68F4A79`
                                            }
                                        }
                                    }}
                                >
                                    <NotificationsIcon
                                        sx={{
                                            backgroundColor: "#FFC107",
                                            borderRadius: '50%',
                                            height: 15,
                                            width: 15
                                        }}
                                    />
                                </Box>
                            </Avatar>
                        </Box>
                        <Box>
                            <Box textAlign={'start'}>
                                <Typography variant={'subtitle1'}  fontWeight={'bolder'}>{title}</Typography>
                                <Typography variant={'body1'}>{message}</Typography>
                            </Box>
                            <Box textAlign={'right'}>
                                <Button variant={'text'} onClick={() => {
                                    toast.dismiss(t.id);
                                    resolve();
                                }}>Dismiss</Button>
                            </Box>


                            {/*<Button size={"small"} variant={'outlined'} >*/}
                            {/*    Dismiss*/}
                            {/*</Button>*/}
                        </Box>

                    </Box>,
                {
                    title,
                    duration: Infinity,
                    // duration: duration,
                    onClosed: resolve,
                    style: {
                        borderRadius: '10px',
                        border: '1px rgba(107,66,187,0.29) solid',
                        background: 'rgba(196,191,191,0.29)',
                        "&:hover": {
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px"
                        },
                    },
                }
            );
        })
    } else {
        await new Promise((resolve) => {
            const toastId = toast[toastType]( // เรียกใช้ฟังก์ชันตาม toastType ที่กำหนดไว้ใน getTypeForToast
                <Box
                    sx={{
                        textAlign: 'start',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',

                    }}
                    // onClick={() => {
                    //     toast.dismiss(toastId);
                    //     resolve();
                    // }}
                >
                    <b>{message}</b>
                    <Box textAlign={'right'}>
                        <Button variant={'text'} onClick={() => {
                            toast.dismiss(toastId);
                            resolve();
                        }}>Dismiss</Button>
                    </Box>
                </Box>,
                {
                    title,
                    // duration: Infinity,
                    duration: duration,
                    onClosed: resolve,
                    style: {
                        color: `${toastType}`,
                        borderRadius: '10px',
                        border: '1px rgba(107,66,187,0.29) solid',
                        background: 'rgba(196,191,191,0.29)',
                        "&:hover": {
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px"
                        },
                    },
                }
            );
        });

    }

    // ดำเนินการต่อที่นี่หลังจากที่ Toast ถูกปิด
}

function getTypeForToast(type) {
    switch (type) {
        case 'success':
            return 'success';
        case 'error':
            return 'error';
        case 'loading':
            return 'loading';
        default:
            return 'info';
    }
}
