export const tokens = {
  common: {
    languageChanged: 'common.languageChanged'
  },
  nav: {
    overview: 'nav.overview',

    //+++++++++++++++++++++++++++++++
    //claimService
    claimService: 'nav.claimService',
    callCenter: 'nav.callCenter',
    claimDetails: 'nav.claimDetails',
    claimCreate: 'nav.claimCreate',
    claimEdit: 'nav.claimEdit',


    supervisor: 'nav.supervisor',
    ossSupervisor: 'nav.ossSupervisor',
    claimManagement: 'nav.claimManagement',
    claimManagementForm: 'nav.claimManagementForm',

    monitor: 'nav.monitor',
    surveyors: 'nav.surveyors',
    report: 'nav.report',
    claimList: 'nav.claimList',


  }
};
