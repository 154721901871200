import axios from "axios";
import {API_URL} from "src/config";
import BaseAlert from "src/components/baseAlert";
import {requestForToken} from "../../firebase";

const SignIn = async (param) => {
    let formData = new FormData();

    formData.append("email", param.email);
    formData.append("password", param.password);
    formData.append("FCMToken", param.FCMToken??"");
    try {
        return await axios({
            method: "post",
            url: API_URL + "/login",
            data: formData,
        }).then(response => {
            if (response.status === 200 && !response.data.error_message) {
                sessionStorage.setItem("token", JSON.stringify(response.data.token))
                sessionStorage.setItem("user", JSON.stringify(response.data.user))
                return sessionStorage.getItem("token");
            }else {
                return BaseAlert("error", "error", "Email or Password incorrect!");
            }
        })
    } catch (e) {
        return false
    }
}
const SignOut = async () => {
    await axios.post(API_URL + "/logout", {}, (token)).then(response => {
        if (response.status === 200) {
            sessionStorage.removeItem("token")
            sessionStorage.removeItem("user")
            return window.location.pathname = ''
        }
    })

}
const getUserInfo = async (param) => {
    return axios.get(API_URL + 'user/info',
        {headers: {'Authorization': `${param.data.token_type} ${param.data.access_token}`}}).then(response => {
        if (response.status === 200) {
            sessionStorage.setItem("user", JSON.stringify(response))
            return getToken
        } else {
            return BaseAlert("error", "ผิดพลาด", "ไม่พบข้อมูล");
        }
    })

}
const getToken = () => {
    let tokenData = JSON.parse(sessionStorage.getItem("token"));
    if (tokenData) {
        return {headers: {Authorization: `${tokenData.token_type} ${tokenData.access_token}`}}
    } else {
        return null
    }

}
const getProfile = () => {
    let user = JSON.parse(sessionStorage.getItem("user"))
    if (user) {
        return user
    } else {
        return null
    }
}
const changePassword = async (id,data) => {
    let token = getToken();
    try {
        return await axios.put( API_URL+`/changePassword?id=${id}`,data,  (token)).then(response => {
            if (response.status === 200) {
                return response.data
            } else {
                return BaseAlert("error", "ผิดพลาด", "พบข้อผิดพลาด")
            }
        })
    } catch (e) {
        return false
    }
}
const AuthService = {
    SignIn,
    SignOut,
    getUserInfo,
    getToken,
    getProfile,
    changePassword
}
export default AuthService
export const token = AuthService.getToken()