// PROD
const prod_vapidKey =  'BJEB3Xwzz2R_YO5kx_2oaxWkbVLxO_BxMSFycVIUa0WbmzEz2bN66oflp7lTp7K2wX4UBEHph5JXFDQrDcyW6Gk'
const PROD_config = {
    apiKey: "AIzaSyAse7ERLLOzrSu4qUyE7aTOpXe_W0lIGmE",
    authDomain: "isurvey-azay.firebaseapp.com",
    projectId: "isurvey-azay",
    storageBucket: "isurvey-azay.appspot.com",
    messagingSenderId: "872573927515",
    appId: "1:872573927515:web:2500cc0bcea3a7d6773f7e"
};

// UAT
const UAT_config = {
    apiKey: "AIzaSyAbF8v-6bcvY0He4k7nhyPJg9O3IPhamiE",
    authDomain: "isurveymsg.firebaseapp.com",
    databaseURL: "https://isurveymsg.firebaseio.com",
    projectId: "isurveymsg",
    storageBucket: "isurveymsg.appspot.com",
    messagingSenderId: "743474547904",
    appId: "1:743474547904:web:5b5a5fa6bc9cf14dafb125"
};
const uat_vapidKey = 'BD6gcOviXutAwxinAYDZWqlvVg4hXQXTCKlWnbiAMl42hgZ4nr7cDD5JwcXPFZ5c5QNX3zUhZwDROROe4h0g1UA'

// FIXME USE by site env
export const firebaseConfig = PROD_config
export const vapidKey = prod_vapidKey;

