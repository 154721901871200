import { createResourceId } from 'src/utils/create-resource-id';
import { decode, JWT_EXPIRES_IN, JWT_SECRET, sign } from 'src/utils/jwt';
import { wait } from 'src/utils/wait';
import { users } from './data';
import axios from "axios";
import {API_URL} from "../../config";
import {requestForToken} from "../../firebase";

const STORAGE_KEY = 'users';

// NOTE: We use sessionStorage since memory storage is lost after page reload.
//  This should be replaced with a server call that returns DB persisted data.

const getPersistedUsers = () => {
  try {
    const data = sessionStorage.getItem(STORAGE_KEY);

    if (!data) {
      return [];
    }

    return JSON.parse(data);
  } catch (err) {
    console.error(err);
    return [];
  }
};

const persistUser = (user) => {
  try {
    const users = getPersistedUsers();
    const data = JSON.stringify([...users, user]);
    sessionStorage.setItem(STORAGE_KEY, data);
  } catch (err) {
    console.error(err);
  }
};

class AuthApi {
  async signIn(request) {
    const { username, password } = request;
    // await wait(500);
    return new Promise(async (resolve, reject) => {
      try {
        let formData = new FormData();
        let FCMToken = await requestForToken();
        formData.append("username", username);
        formData.append("password", password);
        formData.append("FCMToken", FCMToken);

        const user = await axios.post(API_URL + "/login", formData).then(response=>response.data);
        if (!user.token ) {
          reject(new Error('Please check your Username or password'));
          return;
        }

        // Create the access token
        // ToDO Auth timeout
        // const accessToken = sign({ userId: user.user.data.id }, {token:user.data.token}, { expiresIn: JWT_EXPIRES_IN });
        // const accessToken = sign({ userId: user.data.user.data.id },user.data.token.access_token, {expiresIn:user.data.token.expiresIn});
        const accessToken = user.token;

        sessionStorage.setItem("token", JSON.stringify(user.token))
        sessionStorage.setItem("user", JSON.stringify(user))
        resolve({ accessToken });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        // reject(new Error('Internal server error'));
        reject(new Error('Can not login, Please check your Username or password'));
      }
    });
  }

  async signUp(request) {
    const { email, name, password } = request;

    await wait(1000);

    return new Promise((resolve, reject) => {
      try {
        // Merge static users (data file) with persisted users (browser storage)
        const mergedUsers = [
          ...users,
          ...getPersistedUsers()
        ];

        // Check if a user already exists
        let user = mergedUsers.find((user) => user.email === email);

        if (user) {
          reject(new Error('User already exists'));
          return;
        }

        user = {
          id: createResourceId(),
          avatar: undefined,
          email,
          name,
          password,
          plan: 'Standard'
        };

        persistUser(user);

        const accessToken = sign({ userId: user.id }, JWT_SECRET, { expiresIn: JWT_EXPIRES_IN });

        resolve({ accessToken });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  me(request) {
    const { accessToken } = request;
    return new Promise((resolve, reject) => {
      try {
        const user = JSON.parse(sessionStorage.getItem('user'))

        if (!user) {
          reject(new Error('Invalid authorization token'));
          return;
        }
        resolve({
          id: user.id,
          email: user.email??"",
          name: user.full_name??"",
        });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const authApi = new AuthApi();
