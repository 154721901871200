import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import {projectName} from "../config";

export const Seo = (props) => {
    const { title } = props;

    const fullTitle = title
        ? projectName+ ' | '+ title
        : projectName;

    return (
        <Helmet>
            <title>
                {fullTitle}
            </title>
        </Helmet>
    );
};

Seo.propTypes = {
    title: PropTypes.string
};
