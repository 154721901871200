import PropTypes from 'prop-types';
import { format } from 'date-fns';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import Mail04Icon from '@untitled-ui/icons-react/build/esm/Mail04';
import MessageChatSquareIcon from '@untitled-ui/icons-react/build/esm/MessageChatSquare';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import {
  Avatar,
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Stack,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import { Scrollbar } from 'src/components/scrollbar';

const renderContent = (notification) => {
      return (
        <>
          <ListItemText
            primary={(
              <Box
                sx={{
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
              >
                <Typography
                  sx={{ mr: 0.5 }}
                  variant={"subtitle2"}
                  fontWeight={'bolder'}
                  color={'rgba(53,88,231,0.63)'}
                >
                  {notification.title??""}
                </Typography>
                  <Typography
                      sx={{ mr: 0.5 }}
                      variant={"subtitle2"}
                  >
                      {notification.body??""}
                  </Typography>
              </Box>
            )}
            secondary={(
              <Typography
                color="text.secondary"
                variant="caption"
              >
                {notification.updateDatetime}
              </Typography>
            )}
            sx={{ my: 0 }}
          />
        </>
      );
  }

export const NotificationsPopover = (props) => {
  const {
    anchorEl,
    notifications,
    onClose,
    open = false,
    ...other
  } = props;

  const isEmpty = notifications.length === 0;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 380 } }}
      {...other}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 2,
          py: 2
        }}
      >
        <Typography
          color="inherit"
          variant="h6"
        >
          Notifications
        </Typography>
      </Stack>
      {isEmpty
        ? (
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle2">
              There are no notifications
            </Typography>
          </Box>
        )
        : (
          <Scrollbar sx={{ maxHeight: 400 }}>
            <List disablePadding>
              {notifications.map((notification) => {
                  return <ListItem
                        divider
                        key={notification.id}
                        sx={{
                          alignItems: 'flex-start',
                          '&:hover': {
                            backgroundColor: 'action.hover'
                          },
                          '& .MuiListItemSecondaryAction-root': {
                            top: '24%'
                          }
                        }}
                      >
                        {renderContent(notification)}
                      </ListItem>
              }

             )}
            </List>
          </Scrollbar>
        )}
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
