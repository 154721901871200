import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import {requestForToken} from "../firebase";
import {claimServiceAPI} from "../api/claimServiceAPI";
import {usePageView} from "../hooks/use-page-view";
import {useCountdownContext} from "src/contexts/countdown-context";
const ControllerCountdown = (props) => {
    let {showCountdown, setShowCountdown, tempState, setTempState,time_count,timeLeft, setTimeLeft} = useCountdownContext();
    let {notification,notifyCaseID,clearNotifyState} = props

    const closeCountdown = async ()=>{

        if (parseInt(tempState?.caseID)== notifyCaseID){
            setShowCountdown(false)
            setTempState(null)
            setTimeLeft(time_count)
            clearNotifyState()
        }
    }

    usePageView();
    useEffect(() => {
        if (notifyCaseID){
            closeCountdown().then(()=>{
                if (parseInt(tempState?.caseID)== notifyCaseID){
                    return Swal.fire({
                        title:notification.title??"",
                        icon:notification.notifyName??"info",
                        text:notification.body??""
                    })
                }
            })
        }

    }, [notifyCaseID]);
};

export default ControllerCountdown;

