import { getApps, initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { firebaseConfig,vapidKey } from './firebase-config';

if (!getApps().length) {
    initializeApp(firebaseConfig);
}
let messaging = null;
if (typeof window !== 'undefined') {
    messaging = getMessaging();
}

export const requestForToken = () => {
    if (!messaging) {
        return Promise.reject(new Error('Firebase Messaging is not available.'));
    }

    return getToken(messaging, { vapidKey: vapidKey })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client:', currentToken);
                return currentToken;
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token:', err);
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        if (!messaging) {
            resolve(null);
        }

        onMessage(messaging, (payload) => {
            console.log('payload:', payload);
            resolve(payload);
        });
    });
