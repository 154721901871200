import { useCallback, useEffect, useState } from 'react';
import { usePathname } from 'src/hooks/use-pathname';

export const useSideNav = () => {
    const pathname = usePathname();
    const [open, setOpen] = useState(true);

    const handlePathnameChange = useCallback(() => {
        if (open) {
            setOpen(true);
        }
    }, [open]);

    useEffect(() => {
            handlePathnameChange();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pathname]);

    const handleOpen = useCallback(() => {
        setOpen((prevState) => !prevState);
    }, []);

    const handleClose = useCallback(() => {
        setOpen((prevState) => !prevState);
    }, []);

    return {
        handleOpen,
        handleClose,
        open
    };
};
