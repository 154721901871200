// CountdownContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const CountdownContext = createContext();
let time_count = 60; // 1 minute

export const useCountdownContext = () => {
    return useContext(CountdownContext);
};

export const CountdownProvider = ({ children }) => {
    const [tempState, setTempState] = useState(null);
    const [showCountdown, setShowCountdown] = useState(false);
    const [notifyCaseID, setNotifyCaseID] = useState("");
    const [timeLeft, setTimeLeft] = useState(time_count);

    useEffect(() => {

    }, [showCountdown, tempState]);

    return (
        <CountdownContext.Provider value={{ tempState, setTempState, showCountdown, setShowCountdown, notifyCaseID,setNotifyCaseID,timeLeft, setTimeLeft,time_count}}>
            {children}
        </CountdownContext.Provider>
    );
};
