import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import {createTheme, Stack} from "@mui/material";
import {Box} from "@mui/system";
import logo from "../icons/allianz.png";


const Page = () => {
    const theme = createTheme({
        typography: {
            fontFamily: [
                'Noto Sans Thai',
            ].join(','),
        },
    });


    usePageView();

  return (
    <>
      <Seo />
      {/*<main>*/}
          <Stack sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              flex: '1 1 auto',
              marginTop: 'auto',
              marginBottom: 'auto',
          }}>
              <Box component="img" src={logo} alt="Logo" sx={{ height:'40%',width:'auto' }} />
          </Stack>
      {/*</main>*/}
    </>
  );
};

export default Page;
