import {useCallback, useEffect, useMemo, useState} from 'react';
import Bell01Icon from '@untitled-ui/icons-react/build/esm/Bell01';
import { Badge, IconButton, SvgIcon, Tooltip } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { NotificationsPopover } from './notifications-popover';
import {useMounted} from "src/hooks/use-mounted";

const useNotifications = () => {
  const isMounted = useMounted();
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
        handleFetchtData();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []);
  const handleFetchtData = useCallback(async () => {
    try {
      // const response = await notifyAPI.getListNotify();
      const response = null;
      if (isMounted()) {
        setNotifications((response&&response.list_response.data)??[])
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  return {
    notifications,
  };
};

export const NotificationsButton = () => {
  const popover = usePopover();
  const {  notifications} = useNotifications();

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          ref={popover.anchorRef}
          onClick={popover.handleOpen}
        >
          <Badge
            color="error"
            // badgeContent={unread}
          >
            <SvgIcon>
              <Bell01Icon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover
        anchorEl={popover.anchorRef.current}
        notifications={notifications}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
