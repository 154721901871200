import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { IssuerGuard } from 'src/guards/issuer-guard';
import { GuestGuard } from 'src/guards/guest-guard';
import { Layout as AuthLayout } from 'src/layouts/auth/classic-layout';
import { Issuer } from 'src/utils/auth';
// AppAuth
const AppLoginPage = lazy(() => import('src/pages/auth/appAuth/login'));
export const authRoutes = [
  {
    path: 'auth',
    children: [
      {
        path: 'appAuth',
        element: (
            <IssuerGuard issuer={Issuer.appAuth}>
              <GuestGuard>
                <AuthLayout>
                  <Outlet />
                </AuthLayout>
              </GuestGuard>
            </IssuerGuard>
        ),
        children: [
          {
            path: 'login',
            element: <AppLoginPage />
          },
          {
            path: 'login',
            element: <AppLoginPage />
          },
        ]
      },
    ]
  }
];
