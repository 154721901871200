import {endOfDay, startOfDay} from 'date-fns'
import {deepCopy} from 'src/utils/deep-copy'
import {applyPagination} from 'src/utils/apply-pagination'
import axios from "axios";
import {API_URL, defaultValue, TempDataClamApproval, url_API_mobile_data} from "../../config";
import {token} from "../initialAuth";
import authService from "../auth/authService";
import Swal from "sweetalert2";
import AuthService from "../auth/authService";

export class ClaimServiceAPI {
    getOverview=async (request = {}) => {
        let overview = await axios.get(API_URL+'/claim/cnt?',token).then((res)=>res.data);
        // let overview =  [
        //     {
        //         "cnt_all": 1,
        //         "cnt_noti": 1,
        //         "cnt_inform": 0,
        //         "cnt_inspect": 0,
        //         "cnt_finish": 0,
        //         "cnt_wait": 0,
        //         "cnt_consult": 0,
        //         "cnt_deny": 0,
        //         "cnt_cancel": 0,
        //         "cnt_end": 0
        //     }
        // ]
        return {
            overview,
        }
    }


    getListData=async (request = {}) => {
        const {
            tabstate,
            namedrv,
            phonedrv,
            plateno,
            notiNumber,
            informdate,
        } = request
        // let response = TempDataClamApproval
        let response = await axios.get(API_URL+`/claim/dashboard?tabstate=${tabstate}
                                                                            &namedrv=${namedrv??null}
                                                                            &phonedrv=${phonedrv??null}
                                                                            &plateno=${plateno??null}
                                                                            &notiNumber=${notiNumber??null}
                                                                            &informdate=${informdate??null}`

                                                            ,token)
        return {
            response,
        }
    }
    getDataByNotify=async (qString = {}) => {
        // let response = TempDataClamApproval
        let response = await axios.get(API_URL+`/getClaimGateway`+qString ,token)
        return {
            response,
        }
    }

    getClaim=async (caseID = {}) => {
        let response = await axios.get(API_URL+'/claim/edit/'+caseID,token);
        return {
            response,
        }
    }
    createClaim=async (data = {}) => {
        let response = await axios.post(API_URL+'/claim/addcase/',data,(token))
        return {
            response,
        }
    }
    reDispatch=async (caseID) => {
        let response = await axios.get(API_URL+`/reDispatch?id=${caseID}&userID=${authService.getProfile().userID}`,(token))
        return {
            response,
        }
    }

    // FIXME with token
    findSurveyor=async (caseID,data) => {
        let response = await axios.get(API_URL+`/findSurveyor?acc_lat=${data?.acc_lat}&acc_lon=${data?.acc_lon}&jobtype=AUTO&caseID=${caseID}`, (token))
        return {
            response,
        }
    }
    postdispatch=async (data) => {
        let response = await axios.post(API_URL+"/claim/postdispatch", data,(token))
        return {
            response,
        }
    }

    // FIXME with token
    postFCMAPI=async (data, surveyorID,jobType,caseID) => {
        let response = await axios.get(API_URL + `/postFCM?acc_lat=${data.acc_lat}&acc_lon=${data.acc_lon}&jobtype=${jobType}&caseID=${caseID}`, (token))
        return {
            response,
        }
    }
    updateClaim=async (data) => {
        let response = await axios.post(API_URL+'/claim/editcase/',data,(token))
        return {
            response,
        }
    }
    updateCancelCase=async (data) => {
        let response = await axios.post(API_URL+'/claim/cancelcase/',data,(token))
        return {
            response,
        }
    }
    updateEndCase=async (data) => {
        let response = await axios.post(API_URL+'/claim/endcase/',data,(token))
        return {
            response,
        }
    }

    sendWebNotify=async (caseID,tokenFCM) => {
        let data={
            caseID:caseID,
            tokenFCM:tokenFCM
        }
        let response = await axios.post(API_URL+`/listTask/sendWebNotify`, {data}, (token))
        return {
            response,
        }
    }

    checkingStatus=async (caseID = {}) => {
        let response = await axios.get(API_URL+'/checkingStatus/'+caseID,(token))
        return {
            response,
        }
    }
    checkingStatusDispatch=async (caseID = {}) => {
        let response = await axios.get(API_URL+'/listTask/case/'+caseID,(token))
        return {
            response,
        }
    }
    getListMonitor=async (searchState = {}) => {
        let {search} = searchState
        let response = await axios.get(API_URL+'/listTask?search='+search,(token))
        return {
            response,
        }
    }
    getListSurveyorActive=async (searchState = {}) => {
        let {statusID,nameSearch,companyID} = searchState
        let response = await axios.get(API_URL+`/getListSurveyorActive?statusID=${statusID?.sttsvy_ID??""}&name=${nameSearch}&companyID=${companyID}`,(token))
        return {
            response,
        }
    }
    getOverviewSurveyorActive=async () => {
        let response = await axios.get(API_URL+`/getOverviewSurveyorActive`,(token))
        return {
            response,
        }
    }
    updateCaseSurveyor= async(data) =>{
        let response = await axios
            .post(
                API_URL + `/updateCaseSurveyor?caseID=${data.caseID}`,
                data,
                (token)
            )
        return {
            response,
        }
    }
    sendEmail= async(data) =>{
        let response = await axios
            .post(
                API_URL + `/sendEmail`,
                data,
                (token)
            )
        return {
            response,
        }
    }

    dispatchIsurvey=async (caseID) => {
        let response = await axios.post(API_URL+`/dispatchIsurvey`,({caseID: caseID,userID:authService.getProfile().userID}),(token))
        return {
            response,
        }
    }

    getPolicyDetail=async (caseID = {}) => {
        let response = await axios.get(API_URL+`/claim/policyDetail/${caseID}`,token);
        return {
            response,
        }
    }
}

export const claimServiceAPI = new ClaimServiceAPI()
