import {tokens} from '../tokens';

export const en = {


  //+++++++++++++++++++++++++++++++

  //Section name of menu
  [tokens.nav.overview]: 'Overview',

  //Claim Service
  [tokens.nav.claimService]: 'การรับแจ้ง',
  [tokens.nav.callCenter]: 'Call Center',
  [tokens.nav.claimDetails]: 'ข้อมูลการรับแจ้ง',
  [tokens.nav.claimCreate]: 'การรับแจ้งใหม่',
  [tokens.nav.claimEdit]: 'แก้ไขการรับแจ้ง',

  //Claim Service
  [tokens.nav.supervisor]: 'Supervisor',
  [tokens.nav.ossSupervisor]: 'OSS Supervisor',
  [tokens.nav.claimManagement]: 'Claim Approval',
  [tokens.nav.claimManagementForm]: 'Claim Management Form',

  [tokens.nav.monitor]: 'ติดตามงาน',
  [tokens.nav.surveyors]: 'Surveyor Online',
  [tokens.nav.report]: 'รายงาน',

  [tokens.nav.claimList]: 'รายการรับแจ้ง',



};
