import {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import CreditCard01Icon from '@untitled-ui/icons-react/build/esm/CreditCard01';
import Settings04Icon from '@untitled-ui/icons-react/build/esm/Settings04';
import User03Icon from '@untitled-ui/icons-react/build/esm/User03';
import {
    Box,
    Button,
    Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    SvgIcon,
    Typography
} from '@mui/material';
import { RouterLink } from 'src/components/router-link';
import { useAuth } from 'src/hooks/use-auth';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { Issuer } from 'src/utils/auth';
import authService from "../../../api/auth/authService";
import Swal from "sweetalert2";
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from "@mui/icons-material/Logout";

export const AccountPopover = (props) => {
    const { anchorEl, onClose, open, ...other } = props;
    const router = useRouter();
    const auth = useAuth();
    const user = auth.user??null;
    let profile = authService.getProfile()

    const handleLogout = useCallback(async () => {
       await onClose()
        Swal.fire({
            title: 'ต้องการออกจากระบบ?',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#F79009',

        }).then(async (result) => {
            if (result.isConfirmed){
                try {
                    onClose?.();
                    await authService.SignOut()
                    // await auth.signOut();
                    // await sessionStorage.clear();
                    // await localStorage.clear();
                    // return window.location.href = paths.claimList.index;
                } catch (err) {
                    console.error(err);
                    toast.error('Something went wrong!');
                }
            }
        })

    }, [auth, router, onClose]);

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom'
            }}
            disableScrollLock
            onClose={onClose}
            open={!!open}
            PaperProps={{ sx: { width: 200 } }}
            {...other}>
            <Box sx={{ p: 2 }}>
                <Typography variant="body1">
                    {(profile&&profile.username)??""}
                </Typography>
            </Box>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    p: 1,
                    justifyContent: 'center'
                }}
            >
                <Button
                    startIcon={<KeyIcon/>}
                    color="inherit"
                    onClick={()=>window.location.href = paths.changePassword}
                    size="small"
                >
                    เปลี่ยนรหัสผ่าน
                </Button>
            </Box>
            <Divider sx={{ my: '0 !important' }} />
            <Box
                sx={{
                    display: 'flex',
                    p: 1,
                    justifyContent: 'center'
                }}
            >
                <Button
                    startIcon={<LogoutIcon/>}

                    color="inherit"
                    onClick={handleLogout}
                    size="small"
                >
                    ออกจากระบบ
                </Button>
            </Box>
        </Popover>
    );
};

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool
};
