import {useRoutes} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {Provider as ReduxProvider} from 'react-redux';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {RTL} from './components/rtl';
import {SplashScreen} from './components/splash-screen';
import {Toaster} from './components/toaster';
import {SettingsConsumer, SettingsProvider} from './contexts/settings-context';
import {AuthConsumer, AuthProvider} from './contexts/auth/appAuth-context';
import {gtmConfig} from './config';
import {useNprogress} from './hooks/use-nprogress';
import {useAnalytics} from './hooks/use-analytics';
import {routes} from './routes';
import {store} from './store';
import {createTheme} from './theme';
// Remove if react-quill is not used
import 'react-quill/dist/quill.snow.css';
// Remove if react-draft-wysiwyg is not used
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// Remove if simplebar is not used
import 'simplebar-react/dist/simplebar.min.css';
// Remove if mapbox is not used
import 'mapbox-gl/dist/mapbox-gl.css';
// Remove if locales are not used
import './locales/i18n';
import {onMessageListener, requestForToken} from "./firebase";
import React, {useEffect, useState} from "react";
import BaseAlert from "./components/baseAlert";
import Countdown from "./components/countDown";
import {CountdownProvider, useCountdownContext} from "./contexts/countdown-context";
import {useSnackbar} from "notistack";
import {IconButton, Tooltip} from "@mui/material";
import {usePageView} from "./hooks/use-page-view";
import ControllerCountdown from "./components/controllerCountdown";


export const App = () => {
    let { enqueueSnackbar, closeSnackbar } = useSnackbar();

    usePageView();

    const [notification, setNotification] = useState({type: 'info', title: '', message: ''});

    const [messageId, setMessageId] = useState('');
    const [notifyCaseID, setNotifyCaseID] = useState('');

    useAnalytics(gtmConfig);
    useNprogress();

    onMessageListener()
        .then(async (payload,) => {
            console.log("payload",payload)
            if (payload){
                let data = payload?.data ??null
                let notification = payload?.notification??null
                let messageId = payload?.messageId??null

                let notifyNowCaseID = data?(parseInt(data?.[`caseID`])):null;


                await setNotifyCaseID(notifyNowCaseID)
                await setNotification({
                    title: notification?.title,
                    body: notification?.body,
                    notifyName:data?.name,
                    caseID:notifyNowCaseID,
                    messageId: messageId
                });
                await setMessageId(()=>messageId)
                await console.log("messageId",messageId)
                await BaseAlert('info',payload?.notification?.title,payload?.notification?.body)
            }
        })
        .catch((err) => console.log('failed: ', err));

    useEffect(() => {
        const fetchFCMToken = async () => {
            const token = await requestForToken();
            if (!token){
                await BaseAlert('info', 'Warning! We are unable to access "Notification permission" in your browser.', 'As a result, the system will be unable to send notifications to you.');
            }
        };
        fetchFCMToken();
    }, []);
    const clearNotifyState =()=>{
        setNotification({type: 'info', title: '', message: ''})
        setMessageId('')
        setNotifyCaseID('')
    }
    const element = useRoutes(routes);

    return (
        <ReduxProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AuthProvider>
                    <AuthConsumer>
                        {(auth) => (
                            <SettingsProvider>
                                <SettingsConsumer>
                                    {(settings) => {
                                        // Prevent theme flicker when restoring custom settings from browser storage
                                        if (!settings.isInitialized) {
                                            // return null;
                                        }

                                        const theme = createTheme({
                                            colorPreset: settings.colorPreset,
                                            contrast: settings.contrast,
                                            direction: settings.direction,
                                            paletteMode: settings.paletteMode,
                                            responsiveFontSizes: settings.responsiveFontSizes,
                                        });
                                        theme.typography = {
                                            ...theme.typography,
                                            fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback
                                            fontWeightRegular: 400,
                                            fontWeightMedium: 500,
                                            fontWeightBold: 700,
                                            body1:{
                                                fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback
                                            },
                                            body2:{
                                                fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback

                                            },
                                            subtitle1:{
                                                fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback

                                            },
                                            subtitle2:{
                                                fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback

                                            },
                                            caption:{
                                                fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback

                                            },
                                            overline:{
                                                fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback

                                            },
                                            button:{
                                                fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback
                                            },
                                            h1:{
                                                fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback
                                            }, h2:{
                                            fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback

                                            }, h3:{
                                            fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback

                                            }, h4:{
                                            fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback

                                            }, h5:{
                                            fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback

                                            }, h6: {
                                            fontFamily: 'Noto Sans Thai, sans-serif', // ใช้ 'sans-serif' เป็น fallback

                                            }

                                        };
                                        // Prevent guards from redirecting
                                        const showSlashScreen = !auth.isInitialized;

                                        return (
                                            <ThemeProvider theme={theme}>
                                                <Helmet>
                                                    <meta
                                                        name="color-scheme"
                                                        content={settings.paletteMode}
                                                    />
                                                    <meta
                                                        name="theme-color"
                                                        content={theme.palette.neutral[900]}
                                                    />
                                                </Helmet>
                                                <RTL direction={settings.direction}>
                                                    <CssBaseline/>
                                                    {showSlashScreen
                                                        ? <SplashScreen/>
                                                        : (
                                                            <>
                                                                <CountdownProvider>
                                                                    <ControllerCountdown notifyCaseID={notifyCaseID} notification={notification} clearNotifyState={clearNotifyState}  />
                                                                    <Countdown   />

                                                                    {element}

                                                                </CountdownProvider>
                                                            </>
                                                        )}
                                                    <Toaster/>
                                                </RTL>
                                            </ThemeProvider>
                                        );
                                    }}
                                </SettingsConsumer>
                            </SettingsProvider>
                        )}
                    </AuthConsumer>
                </AuthProvider>
            </LocalizationProvider>
        </ReduxProvider>
    );
};
