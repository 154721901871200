import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { requestForToken } from "../firebase";
import { claimServiceAPI } from "../api/claimServiceAPI";
import { usePageView } from "../hooks/use-page-view";
import { useCountdownContext } from "src/contexts/countdown-context";
import { Avatar, Button, Dialog, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { toast, Toaster } from "react-hot-toast";


const Countdown = () => {
    usePageView();
    let { showCountdown, setShowCountdown, tempState, setTempState, notifyCaseID,timeLeft, setTimeLeft,time_count } = useCountdownContext();

    const [accepted, setAccepted] = React.useState(false);

    const prevTempStateCaseID = React.useRef(null); // Store the previous caseID

    const pad = (value) => {
        return String(value).padStart(2, '0');
    };

    useEffect(() => {
        if (tempState && tempState.caseID !== prevTempStateCaseID.current) {
            setTimeLeft(time_count);
            prevTempStateCaseID.current = tempState.caseID; // Update the previous caseID
        }
    }, [tempState]);

    useEffect(() => {
        // time out
        const customDispatchNotify = async () => {
            // Your custom logic for dispatching the notification
            await setAccepted(false);
            if (tempState) {
                let tokenFCM = await requestForToken();
                await claimServiceAPI.sendWebNotify(tempState.caseID, tokenFCM);
                let response_status = (await claimServiceAPI.checkingStatusDispatch(tempState.caseID)).response;

                if (response_status) {
                    if (response_status.data && response_status.data.status === true) {
                        await setShowCountdown(() => false);
                        await setTimeLeft(() => time_count);
                        toast.remove("countdown-toast"); // ลบ toast ที่มี ID "countdown-toast"
                        return Swal.fire(
                            "จ่ายงานเรียบร้อย!",
                            "success"
                        ).then((okay) => {
                            if (okay) {

                                // window.location.href = `/claimList`;
                                // navigate(`/Claimlist`, {replace: true})
                            }
                        });
                    }
                } else {
                    await setShowCountdown(() => false);
                    await setTimeLeft(() => time_count);
                    await toast.remove("countdown-toast");
                }
            }
        };

        const interval = setInterval(() => {
            if (timeLeft > 0 && showCountdown) {
                setTimeLeft((prevTime) => prevTime - 1);
                customDispatchNotify().then(() => {});
            }
        }, 10000); // เรียกใช้งานทุก 10 วินาที

        if (timeLeft === 0 && showCountdown) {
            customDispatchNotify().then(() => {});
        }

        return () => {
            clearInterval(interval);
        };
    }, [showCountdown, timeLeft, setShowCountdown, tempState]);

    useEffect(() => {
        if (timeLeft === 0) {
            setShowCountdown(false);
            setTimeLeft(0);
        }
    }, [timeLeft, showCountdown]);

    useEffect(() => {
        const customDispatchNotify = async () => {
            // Your custom logic for dispatching the notification
            await setAccepted(false);
            if (tempState) {
                let tokenFCM = await requestForToken();
                let response_status = (await claimServiceAPI.checkingStatusDispatch(tempState.caseID)).response;
                if (response_status) {
                    if (response_status.data && response_status.data.status === true) {
                        await setTempState(() => null);
                        await setShowCountdown(() => false);
                        await setTimeLeft(() => time_count);
                        toast.remove("countdown-toast"); // ลบ toast ที่มี ID "countdown-toast"
                        return Swal.fire(
                            "จ่ายงานเรียบร้อย!",
                            "success"
                        ).then((okay) => {
                            if (okay.isConfirmed) {
                                // Perform any navigation or actions here
                            }
                        });
                    }
                } else {
                    await setShowCountdown(() => false);
                    await setTimeLeft(() => time_count);
                    toast.remove("countdown-toast");
                }
            }
        };

        if (tempState && showCountdown) {
            const dispatchInterval = setInterval(customDispatchNotify, 10000);
            return () => {
                clearInterval(dispatchInterval);
            };
        }
    }, [tempState, showCountdown]);

    useEffect(() => {
        let countdownInterval;

        if (showCountdown && tempState !== null && tempState !== '') {
            countdownInterval = setInterval(() => {
                setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : prevTime));
            }, 1000);
        }

        return () => {
            clearInterval(countdownInterval);
            if (timeLeft === 0) {
                setShowCountdown(false);
                setTempState(null);
                setTimeLeft(time_count);
            }
        };
    }, [showCountdown, tempState]);

    if (showCountdown && timeLeft > 0) {
        toast((t) => (
            <Box
                sx={{
                    color: "#000000",
                    textAlign: 'start',
                    position: 'relative',
                    minWidth: '250px'
                }}
            >
                <Box>
                    <Typography fontSize={16} fontWeight={'bolder'}>กำลังจ่ายงาน  : {`00:${timeLeft > 0 ? pad(timeLeft) : '00'}`}</Typography>
                </Box>
            </Box>
        ), {
            id: "countdown-toast", // ใช้ ID เพื่ออัพเดต toast แทนที่การสร้างใหม่
            title: 'กำลังจ่ายงาน',
            duration: Infinity,
            style: {
                borderRadius: '10px',
                border: '1px rgba(107,66,187,0.29) solid',
                background: 'rgba(196,191,191,0.29)',
                "&:hover": {
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px"
                },
            },
        });
    }else {
        toast.remove("countdown-toast");
    }
    return null;
};

export default Countdown;
