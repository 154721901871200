import {Backdrop, Box, CircularProgress, Typography} from '@mui/material';
import {warning} from "../theme/colors";

export const SplashScreen = () => (
    <Box
        sx={{
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // สีเทาๆโปร่งแสง
            backdropFilter: 'blur(10px)', // สร้างเอฟเฟกต์ blur ให้กับพื้นหลัง
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            justifyContent: 'center',
            left: 0,
            p: 3,
            position: 'fixed',
            top: 0,
            width: '100vw',
            zIndex: 1400,
            color:'white'
        }}
    >
        <CircularProgress sx={{color:`${warning.main}`}}  size={120}/>
        <Typography  position="absolute" fontWeight={"bold"}> Loading... </Typography>
    </Box>
);
